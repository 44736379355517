import { useWidth } from "./use-width.hook";

function useMediaQuery() {
	const width = useWidth();
	return {
		isMobile: ["xs", "sm"].includes(width),
		isTablet: ["md"].includes(width),
		isDesktop: ["lg", "xl"].includes(width),
	};
}

export default useMediaQuery;
