import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import useWindowSize from "custom-hooks/useWindowsSize.hook";
import SaveSearchQueryButton from "./save-search.button";

export interface IResultFilterBar {
  resultsFilters: any;
  filters: any;
  isLoading: boolean;
}

const handleClick = (id: string, isMobile: boolean) => {
  document.getElementById(isMobile ? id : "modal-propertyList")?.click();
};

const ResultFilterBar = (props: IResultFilterBar) => {
  const { resultsFilters, filters, isLoading } = props;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const translation = useTranslations("realestateList");
  const size = useWindowSize();
  const width: any = size?.width;

  useEffect(() => {
    if (width > 899) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  const showSaveSearch = true;

  return (
    <Container>
      <Filters>
        {resultsFilters?.cities.length > 0 && (
          <Item onClick={() => handleClick("locations", isMobile)}>
            {translation("city")}:
            <Result> {resultsFilters?.cities.join(", ")}</Result>
          </Item>
        )}

        {resultsFilters.sector.length > 0 && (
          <Item onClick={() => handleClick("filterSector", isMobile)}>
            SECTOR:
            <Result> {resultsFilters?.sector.join(", ")}</Result>
          </Item>
        )}

        {resultsFilters.typeProperty.length > 0 && (
          <Item onClick={() => handleClick("filterType", isMobile)}>
            {translation("type")}:
            <Result> {resultsFilters?.typeProperty.join(", ")}</Result>
          </Item>
        )}
      </Filters>

      {!isLoading && (
        <Counter>
          ({translation("count", { count: resultsFilters?.count ?? null })})
          {showSaveSearch && <SaveSearchQueryButton filters={filters} />}
        </Counter>
      )}
    </Container>
  );
};

export default ResultFilterBar;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 30px auto;
  @media screen and (max-width: 899px) {
    margin: 20px auto 30px;
  }
`;

const Filters = styled.ul`
  background-color: var(--color-blue-main);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 6px 20px;
  border-radius: 50px;
  list-style: none;
  margin-bottom: 10px;
  max-width: fit-content;
  @media screen and (max-width: 867px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 30px;
    gap: 10px;
  }
`;

const Item = styled.li`
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  text-transform: uppercase;
  & a {
    color: #fff;
    text-decoration: none;
  }
  @media screen and (max-width: 867px) {
    max-width: 550px;
  }
`;

const Result = styled.span`
  font-weight: lighter;
`;

const Counter = styled.p`
  font-size: 14px;
  color: var(--color-gray-01);
  font-style: italic;
  max-width: fit-content;
  text-align: center;
`;
