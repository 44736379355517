import React from "react";
import styled from "@emotion/styled";
import { useTranslations } from "next-intl";

import Menu from "./Menu";
import { useIntlPropertyTypes } from "shared/models/const/property-types";

import { DefaultGroup } from "shared/components/checbox-group";

function Content(props: any) {
  const { onTypeChange, typeProperty = [], collection } = props;
  const translation = useTranslations("realestateList.typeFilters");

  const values = {
    typeProperty,
  };

  const propertyTypes = useIntlPropertyTypes().map((item) => ({
    label: item.name,
    value: item.slug,
  }));

  const collectionProperties = [
    translation("house"),
    translation("apartment"),
    translation("tourist"),
    translation("penthouse"),
    translation("project"),
  ];

  const collectionTypes = propertyTypes.filter((e) =>
    collectionProperties.includes(e.label)
  );

  const items = collection ? collectionTypes : propertyTypes;

  return (
    <Wrapper>
      <Options>
        <StyledGroup
          columns={12}
          items={items}
          row={false}
          onChange={onTypeChange}
          values={values}
          name="typeProperty"
        />
      </Options>
    </Wrapper>
  );
}

function TypeFilters(props: any) {
  const { children, ...rest } = props;

  const Children = React.cloneElement(props.children);

  return <Menu content={<Content {...rest} />}>{Children}</Menu>;
}

export default TypeFilters;

const Wrapper = styled.div`
  background-color: #f7f6f4;
  border-radius: 10px;
  padding: 18px 13px 13px;
`;

const Options = styled.div`
  width: 100%;
`;

const StyledGroup = styled(DefaultGroup)`
  & .MuiFormControlLabel-root {
    margin-left: 0;
  }

  & .MuiCheckbox-root {
    padding: 5px;
    color: #1f3564;
  }

  & .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;
