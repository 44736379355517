import { SearchQueriesEndpoints } from "@endpoints/clients/search-queries/search-queries.endpoints";
import { PaginatedResponse } from "@interfaces/response/paginated-response.interface";
import { ISearchQueryResponseData } from "@interfaces/response/search-query-response.interface";
import { ISearchQuery } from "@interfaces/search-query.interface";
import { deleteRequest, getRequest, postRequest } from "api/base-services.request";

export function saveSearchQuery(body: ISearchQuery) {
  return postRequest(SearchQueriesEndpoints.save, body).then(({ data }) => data.data);
}
export function getSearchQueries() {
  return getRequest<PaginatedResponse<ISearchQueryResponseData[]>>(SearchQueriesEndpoints.list)
    .then(({ data }) => data);
}
export function deleteSearchQuery(id: number | string) {
  return deleteRequest(SearchQueriesEndpoints.delete(id)).then(({ data }) => data.data);
}