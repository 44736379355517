import React from "react";
import styled from "@emotion/styled";
import { FormControlLabel } from "@mui/material";

const COLORS = {
  primary: {
    "--background-color": "#E92027",
  },
  secondary: {
    "--background-color": "#1f3564",
  },
};

const PADDING = {
  end: {
    "--padding": "2px 0 0 5px",
  },
  top: {
    "--padding": "0 0 5px 0",
  },
  start: {
    "--padding": "2px 5px 0 0",
  },
  bottom: {
    "--padding": "5px 0 0 0",
  },
};

function DefaultRadio(props: IRadioInterface) {
  const {
    color = "primary",
    label,
    labelPlacement = "start",
    value,
    className,
    type = "radio",
    ...rest
  } = props;
  const colors = COLORS[color];
  const padding = PADDING[labelPlacement];
  const styles:any = { ...colors, ...padding };

  if (label) {
    return (
      <StyledLabel
        control={<StyledRadio value={value} type={type} {...rest} />}
        value={value}
        label={label}
        labelPlacement={labelPlacement}
        style={styles}
        className={className}
      />
    );
  }

  return (
    <StyledRadio className={className} style={styles} type={type} {...rest} />
  );
}

const StyledRadio = styled.input`
  width: 20px;
  height: 20px;
  background-color: #8c8c8c;
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  color: var(--colors);
  appearance: none;
  overflow: visible;

  &:checked {
    background-color: var(--background-color);
  }
`;

const StyledLabel = styled(FormControlLabel)`
  margin: 0;
  padding: 0;

  & .MuiFormControlLabel-label {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    font-weight: 400;
    padding: var(--padding);
    color: var(--color-blue-main);
  }
`;

interface IRadioInterface {
  color?: "primary" | "secondary";
  label?: string;
  labelPlacement?: "top" | "start" | "bottom" | "end";
  value?: string | number;
  name?: string;
  className?: string;
  type?: "radio" | "checkbox";
}

export default DefaultRadio;
