import React from "react";
import { useFormikContext } from "formik";
import { useField } from "formik";

import DefaultGroup from "./DefaultGroup";

function FormikGroup(props: any) {
  const [field, meta, helpers] = useField(props as any);
  const { values } = useFormikContext();

  return <DefaultGroup {...props} values={values} {...field} />;
}

export default FormikGroup;
