import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  FormGroup,
  Checkbox,
} from "@mui/material";

import Tooltip from "shared/components/tooltip";
import CheckBoxGroupV2 from "shared/components/checkbox-group-v2";

export function RadioContent(props: any) {
  const { items = [], name, onChange, value } = props;
  return (
    <Wrapper>
      <Options>
        <StyledFormControl>
          <RadioGroup name={name} value={value}>
            {items.map((item: any, index: number) => (
              <StyledLabel
                value={item.value}
                label={item.label}
                control={
                  <StyledRadio
                    onClick={onChange}
                    checked={value === item.value}
                  />
                }
                key={index}
              />
            ))}
          </RadioGroup>
        </StyledFormControl>
      </Options>
    </Wrapper>
  );
}

export function CheckContent(props: any) {
  const { items = [], name, onChange, value } = props;
  return (
    <Wrapper>
      <Options>
        <StyledFormControl>
          <FormGroup>
            {items.map((item: any, index: number) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox onClick={onChange} checked={value === item.value} />
                }
                name={name}
                value={item.value}
                label={item.label}
              />
            ))}
          </FormGroup>
        </StyledFormControl>
      </Options>
    </Wrapper>
  );
}

function Menu(props: any) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const Children = React.cloneElement(props.children);

  return (
    <>
      <StyledTooltip onClose={handleClose} content={props.content} open={open}>
        <div onClick={handleOpen}>{Children}</div>
      </StyledTooltip>
    </>
  );
}

export default Menu;

const StyledTooltip = styled(Tooltip)`
  & .wrapper-content {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  background-color: #f7f6f4;
  border-radius: 10px;
  padding: 18px 13px 13px;
  min-width: 190px;
`;

const Options = styled.div`
  width: 100%;
  display: block;
  overflow: auto;
  max-height: 200px;
`;

const StyledFormControl = styled(FormControl)`
  margin-left: 0px;
`;

const StyledRadio = styled(Radio)`
  padding: 5px;
  color: #1f3564;

  &.Mui-checked {
    color: #4caf50;
  }

  & svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledLabel = styled(FormControlLabel)`
  margin-left: 0;
  & .MuiFormControlLabel-label {
    font-family: var(--font-family);
    color: var(--color-blue-main);
    font-weight: 600;
    font-size: var(--fs-body-small);
    vertical-align: bottom !important;
    line-height: 1.5;
  }
`;
