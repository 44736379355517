import React from "react";
import Button from "@components/Button";
import { css } from "@emotion/css";
import { useMutation } from "@tanstack/react-query";
import { saveSearchQuery } from "api/clients/search-queries/search-queries.request";
import useSnackBar from "custom-hooks/useSnackBar.hook";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useTranslations } from "next-intl";
import { useAppDispatch, useAppSelector } from "custom-hooks/redux.hooks";
import { openAuthDialog } from "store/feature/auth/auth-dialog.slice";
import Link from "next/link";
import { CPagesPath } from "shared/models/const/pages-path";
import styled from "@emotion/styled";
import dataLayerEvent from "custom-hooks/data-layer-event.hook";

interface ISaveSearchQueryButton {
  filters: any;
}

export default function SaveSearchQueryButton({
  filters,
}: ISaveSearchQueryButton) {
  const t = useTranslations("realestateList");
  const { showMessage } = useSnackBar();
  const user = useAppSelector(({ Auth }) => Auth.user);
  const dispatch = useAppDispatch();
  const { event } = dataLayerEvent();

  const { mutate, isLoading, mutateAsync } = useMutation(saveSearchQuery, {
    onSuccess: () => {
      event({
        event_name: "save_search",
        body: {
          ciudad_buscada: filters?.locations?.map(
            (location: any) => location?.description
          ),
          tipo_negocio: filters?.businessTypes,
          tipo_propiedad: filters?.typeProperty,
          sector: filters?.sector?.map((sector: any) => sector?.description),
          habitaciones: filters?.bedrooms,
          precio_maximo: filters?.precio_maximo,
          precio_minimo: filters?.precio_minimo,
        },
      });
      showMessage(
        <SuccessSnackBody>
          {t("searchQuery.success")}&nbsp;
          <Link href={CPagesPath.es.ProfileSearchHistory}>
            {t("searchQuery.link")}
          </Link>
        </SuccessSnackBody>,
        { variant: "success" }
      );
    },
    onError: () => showMessage(t("searchQuery.error"), { variant: "error" }),
  });


  const saveSearch = () => {
    if (!user) return dispatch(openAuthDialog("signin"));
    mutateAsync({ parameters: filters })
  };

  return (
    <Button
      className={saveSearchButton}
      disabled={isLoading}
      onClick={saveSearch}
    >
      <BookmarkBorderIcon />
      {t("searchQuery.save")}
    </Button>
  );
}

const saveSearchButton = css`
  padding: 4px 25px 4px 20px;
  margin-left: 10px;
`;

const SuccessSnackBody = styled.div`
  a {
    color: white;
    font-weight: bold;

    :hover {
      text-decoration: underline;
    }
  }
`;
