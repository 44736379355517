import React, { ReactElement } from "react";
import styled from "@emotion/styled";

const TABSCOLOR = {
  default: {
    "--tabs-color": "var(--color-blue-main)",
  },
};

const TABCOLOR = {
  primary: {
    "--tab-color": "var(--color-secondary)",
  },
};

function RadioTab(props: IRadioTab) {
  const { color = "primary", children, radioGroup, onChange, ...rest } = props;
  const styles: any = TABCOLOR[color];

  const ChildrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const opt: any = {
        radioGroup,
        onChange,
      };
      return React.cloneElement(child, opt);
    }
    return child;
  });

  return (
    <RadioTabSection {...rest} style={styles}>
      {ChildrenWithProps}
    </RadioTabSection>
  );
}

function RadioTabs(props: IRadioTabs) {
  const {
    color = "default",
    children,
    id,
    radioGroup,
    className,
    value,
    onChange,
    checked,
    ...rest
  } = props;
  const styles: any = TABSCOLOR[color];

  return (
    <Wrapper className={className} style={styles}>
      <RadioTabsInput
        type="radio"
        id={id}
        name={radioGroup}
        value={value}
        onChange={onChange}
        checked={checked}
        {...rest}
      />
      <RadioTabsLabel htmlFor={id}>{children}</RadioTabsLabel>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline;
  margin-right: -8px;
  &:last-of-type {
    margin-right: 0;
  }
`;

const RadioTabSection = styled.section`
  width: max-content;
  min-width: min-content;
  border-radius: 0 0 16px 16px;
  padding: 0;
  background-color: transparent;
  height: fit-content;
`;

const RadioTabsInput = styled.input`
  display: none;
  box-sizing: border-box;
  padding: 0;
  overflow: visible;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
`;

const RadioTabsLabel = styled.label`
  padding: 7px 19px 9px;
  display: inline-block;
  background-color: transparent;
  color: #fff;
  border-radius: 0 0 16px 16px;
  position: relative;
  font-size: 0.8rem;
  font-weight: 500;
  min-width: 150px;
  text-align: center;
  cursor: pointer;

  ${RadioTabsInput}:checked + & {
    background-color: var(--tabs-color);
    transform: scale(1.1);
    transition: 0.2s;
    z-index: 1;
  }

  ${RadioTabsInput}:not(:checked) + & {
    background-color: var(--tab-color);
  }
`;

interface IRadioTab {
  color?: "primary";
  children: ReactElement[] | ReactElement;
  radioGroup: string;
  onChange?: React.ReactEventHandler;
}

interface IRadioTabs {
  color?: "default";
  children: string | React.ReactNode;
  id: string;
  radioGroup?: string;
  checked?: boolean;
  className?: string;
  value?: any;
  onChange?: React.ReactEventHandler;
}

export { RadioTab, RadioTabs };
