import { css } from "@emotion/react";
import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import Checkbox, { ICheckBoxComponent } from "shared/components/checkBox";

function DefaultGroup(props: ICheckboxGroupComponents) {
  const {
    values = [],
    items,
    columns,
    className,
    manualChecked = true,
    handleCheckedStatusCustom,
    ...rest
  } = props;
  const styles = {
    root: css({
      width: "100%",
      "& .MuiFormControlLabel-label": {
        fontFamily: "var(--font-family)",
        color: "var(--color-blue-main)",
        fontWeight: "600",
        fontSize: "var( --fs-body-small)",
        verticalAlign: "bottom !important",
      },
    }),
  };

  const handleCustomCheck = (item: any) => {
    if (props?.handleCheckedStatusCustom) {
      return props?.handleCheckedStatusCustom(item.value);
    } else {
      return handleCheckedStatus(item.value);
    }
  };

  const handleCheckedStatus = (value: string | number) => {
    let finalValue = value;
    if (typeof value === "number") {
      finalValue = finalValue.toString();
    }

    // @ts-ignore
    const itemsArray = values[props.name] || [];

    return itemsArray.includes(finalValue);
  };

  return (
    <FormGroup row={props.row} css={styles.root} className={className}>
      {props.items.map((item, i) => (
        <Grid item xs={props.columns ?? 6} key={i}>
          <FormControlLabel
            disabled={item?.disable ?? false}
            checked={item?.checked ?? false}
            control={
              manualChecked ? (
                <Checkbox checked={handleCustomCheck(item)} />
              ) : (
                <Checkbox />
              )
            }
            {...rest}
            label={item.label}
            value={item.value}
          />
        </Grid>
      ))}
    </FormGroup>
  );
}
//

export default DefaultGroup;
export interface ICheckboxGroupComponents {
  name?: string;
  items: { 
    label: string; 
    value: number | string; 
    disable?:boolean;
    checked?: boolean;
  }[];
  row: boolean;
  columns?: number;
  values?: any;
  className?: string;
  manualChecked?: boolean;
  onChange?: any;
  handleCheckedStatusCustom?: (item: any) => boolean;
}
