import styled from "@emotion/styled";
import useGetPersistParams from "custom-hooks/useGetPersintParamas.hook";
import Button from "shared/components/Button";
import React, { useState } from "react";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useWidth } from "custom-hooks/use-width.hook";
import { CPagesPath } from "shared/models/const/pages-path";
import { getRealestateDetails } from "api/realestate.request";
import { ModalMessage } from "shared/components/message-modal";
import { AutoCompleteField } from "shared/components/auto-complete-field";
import useCities from "custom-hooks/use-cities.hook";
import sortCities from "@helpers/cities.helper";
import _ from "lodash";

function SearchBar(props: any) {
  const { value = [], onChange, collection } = props;
  const translation = useTranslations("realestateList");
  const translationNotFound = useTranslations("realestateList.notFound");
  const [search, onSearch] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const placeholder = value?.length > 0 ? "" : translation("searchPlaceholder");
  const width = useWidth();
  const router = useRouter();
  const refer = useGetPersistParams({ useConque: "" });

  const { isLoading, data: cities } = useCities({
    q: search,
  });

  const handleSearch = (e: any) => {
    if (e?.target) {
      onSearch(e.target.value);
    }
  };

  const handleToggleModal = () => {
    setOpen(!open);
  };

  const onSubmit = () => {
    const realestateId = parseInt(search);
    const redirect = collection
      ? CPagesPath.es.collection_property_detail
      : CPagesPath.es.property_detail;

    if (Boolean(realestateId)) {
      getRealestateDetails(realestateId)
        .then((r) => {
          router.push({
            pathname: redirect(r.data),
            query: refer,
          });
        })
        .catch(() => handleToggleModal());
    }
  };

  const handleLocationChange = (e: any, newValue: any) => {
    // if enter was pressed
    if (e.keyCode === 13 && Boolean(parseInt(search))) {
      onSubmit();
    } else {
      if (onChange) {
        if (newValue.length == 2) {
          onChange(e, [newValue[1]]);
          return;
        }
        onChange(e, newValue);
      }
    }
  };

  const items = cities ? sortCities(cities) : [];

  return (
    <Wrapper>
      <CoverAutoComplete
        value={value}
        id="locations"
        name="locations"
        multiple={true}
        loading={isLoading}
        placeholder={placeholder}
        onInputChange={_.debounce(handleSearch, 500)}
        className="city-autocomplete"
        onChange={handleLocationChange}
        freeSolo={Boolean(parseInt(search))}
        options={items}
        getOptionLabel={(option: any) => option?.description}
        limitTags={["xs", "sm"].includes(width) ? 1 : 3}
        renderOption={(props: any, option: any) => (
          <ItemElementLabel {...props} {...option} />
        )}
        noOptionsText={
          !(search?.length > 2)
            ? translation("searchHelp")
            : translation("searchHelpSD")
        }
      />
      <SubmitButton bg="--color-blue-main" onClick={onSubmit}>
        <Image src="/icons/loupe_remax_rd.svg" alt="Loupe icon" />
      </SubmitButton>
      <ModalMessage
        title={translationNotFound("title")}
        body={translationNotFound("body")}
        variant="error"
        open={open}
        handleClose={handleToggleModal}
      />
    </Wrapper>
  );
}

export default SearchBar;

const ItemElementLabel = (props: any) => {
  const { description, ...rest } = props;
  return (
    <Item {...rest}>
      <span className={`noWrap isCity`}>{description}</span>
    </Item>
  );
};

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  @media screen and (max-width: 450px) {
    margin-top: 10px;
  }
`;

const SubmitButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
  border-radius: 15px;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const CoverAutoComplete = styled(AutoCompleteField)`
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;

  & fieldset {
    border: none;
  }

  & .MuiInputBase-fullWidth.MuiInputBase-root {
    flex-wrap: nowrap;
    &.Mui-focused {
      /* position: absolute; */
      flex-wrap: wrap;
    }
  }
  & .MuiInputBase-root {
    background-color: #f7f6f4;
    border-radius: 15px;
    padding: 0;
    border: 1px solid #d0d0d0;

    &.Mui-focused {
      border-radius: 15px;
    }
  }
  & .MuiAutocomplete-tag {
    // max-width: 110px;
    background-color: var(--color-blue-main);
    color: white;
    font-weight: 500;
  }

  & .MuiChip-deleteIcon {
    color: white !important;
  }
  & span.MuiAutocomplete-tagSizeMedium {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding:3px;
  }

  & .Mui-focused .MuiAutocomplete-tag {
    max-width: calc(100% - 40px);
  }

  & .city-autocomplete {
    width: 100%;
  }
`;

const Item = styled.li`
  width: 100%;
  display: flex;
  &.MuiAutocomplete-option.Mui-focused {
    background-color: transparent;
    &:hover span {
      color: white;
    }
  }
  .noWrap {
    white-space: nowrap;
  }
  & span {
    color: var(--color-blue-main);
    font-weight: bold;
    margin-right: 5px;
  }
  & .isCity,
  .isSector {
    color: var(--color-blue-main);
  }

  & .description {
    color: #989694;
    line-height: 20px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: start;
    white-space: nowrap;
    text-align: left;
    font-size: 13px;
    & .isCity {
      width: 100%;
    }
    & .description {
      text-align: left;
      font-weight: 400;
      width: 100%;
    }
  }
`;
