import React, { useRef } from "react";
import styled from "@emotion/styled";
import { PopperUnstyled } from "@mui/base";
import { Fade, ClickAwayListener } from "@mui/material";

function Tooltip(props: any) {
  const {
    open,
    children,
    placement,
    className,
    onClose = () => {},
    content,
    arrow,
    ...rest
  } = props;
  const anchorRef = useRef(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: anchorRef })}
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement}
        className={className}
        modifiers={[
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              rootBoundary: "window",
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
          {
            name: "offset",
            enabled: true,
            options: {
              offset: [0, 8],
            },
          },
        ]}
        {...rest}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener onClickAway={onClose}>
              <Wrapper className="wrapper-content">
                {/* @ts-ignore */}
                {arrow && <Arrow ref={setArrowRef} />}
                {content}
              </Wrapper>
            </ClickAwayListener>
          </Fade>
        )}
      </StyledPopper>
    </div>
  );
}

export default Tooltip;

const Arrow = styled.span`
  position: absolute;
  font-size: 7px;
  width: 38px;
  height: 38px;
  &:before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
  &[data-popper-placement^="bottom"] ${Arrow} {
    top: 0;
    left: 0;
    margin-top: -16px;
    width: 38px;
    height: 30px;
    &:before {
      border-width: 0 16px 16px 16px;
      border-color: transparent transparent #f2f2f2 transparent;
    }
  }
`;

const Wrapper = styled.div`
  padding: 7px 26px;
  background-color: #f2f2f2;
  border-radius: 12px;
  min-width: 100px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
