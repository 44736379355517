enum SearchQueriesStatic {
  /**
   * @member {string}
   * @method POST
   * @version 2.0
   * @description Save search query.
   */
  save = "/v2/clients/save-queries",
  /**
   * @member {string}
   * @method GET
   * @version 2.0
   * @description List search queries.
   */
  list = "/v2/clients/search-queries",
}

const SearchQueriesDynamic = {
  /**
   * @member {string}
   * @method DELETE
   * @version 2.0
   * @description Delete search query.
   */
  delete: (id: number | string) => "/v2/clients/search-queries/" + id,
};

export const SearchQueriesEndpoints = {
  ...SearchQueriesDynamic,
  ...SearchQueriesStatic,
};

export type SearchQueriesEndpoints = typeof SearchQueriesEndpoints;
