import { NextRouter } from "next/router"
import { CPropertyTypes } from "../const/property-types";
import { seoPathname } from "../const/seo.const";

export const titleDynamicProperties = (router: NextRouter) => {
  const params = router.asPath.split('?')[0].split("/")
  if (/^.*(\+|:|\bq\b|%22)+.*$/.test(router.asPath)) {
    return `Resultados de búsqueda`
  } else if (router.pathname === seoPathname) {
    return seoTitleHandler(params.join('/'));
  }
  else if (params.length == 6) {
    return `${params[2]} de ${params[3]}s en ${params[5].replaceAll('-', " ")}, ${params[4].replaceAll('-', " ")} `
  }
  else if (params.length == 5) {
    return `${params[2]} de ${params[3]}s en ${params[4].replaceAll('-', " ")}`
  }
  else if (params.length == 4) {
    return `${params[2]} de ${params[3]}s`
  }
  else if (params.length == 3) {
    return `Propiedades en ${params[2]}`
  }
}

export const seoTitleHandler = (path: string) => {
  const [businessTypes, propertyType, city, sector] = path.substring(1).split("/");
  const propertyTypePlural = CPropertyTypes.find((x) => x.name.toLowerCase() == propertyType.toLowerCase().replace(/-/g, " "))?.plural;
  let result = businessTypes + " de " + propertyTypePlural;

  if (city) {
    result += " en " + city.replace(/-/g, " ");
    if (sector) result += ", " + sector.replace(/-/g, " ");
  } else {
    result += " en República Dominicana";
  }

  return result + " ";
}