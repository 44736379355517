import styled from '@emotion/styled'
import useWindowSize from 'custom-hooks/useWindowsSize.hook'
import React from 'react'
import AdvancedFilters from 'shared/components/advance-filter-dialog'
import Button from 'shared/components/Button'
import { Checkbox } from '@mui/material'
import { RadioTab, RadioTabs } from 'shared/components/radio'
import { css } from '@emotion/react'
import { FormConfig } from 'shared/components/buy-sell-filter/util/form.util'
import { useTranslations } from 'next-intl'
import { useWidth } from 'custom-hooks/use-width.hook'
import { Form, Formik, FormikConfig, FormikProps } from 'formik'

import PriceRangeFilters from './PriceRangeFilter'
import RoomFilters from './RoomFilters'
import SearchBar from './SearchBar'
import SectorFilters from './SectorFilters'
import TypeFilters from './TypeFilters'
import { useShowNews } from 'shared/context/news.context'

// import AdvancedFilters from "./AdvanceFilters";
function CustomLabel(props: any) {
  const { active, label, onChange = () => {} } = props;

  return (
    <LabelWrapper>
      <StyledCheckbox checked={active} onClick={onChange} />
      <Label>{label}</Label>
    </LabelWrapper>
  );
}

function Filters(props: any) {
  const translation = useTranslations("realestateList");
  const {showNews} =  useShowNews()
  const {
    filters = FormConfig.initialValues,
    onBusinessChange,
    onTypeChange,
    onBedroomsChange,
    handleAdvancedFilters,
    onLocationsChange,
    collection,
    onSectorsChange,
    onCurrencyChange,
    onAmountChange,
  } = props;
  const width = useWidth();
  const isMobile = ["xs", "sm"].includes(width);
  const size = useWindowSize();
  const filtersOptions = [
    {
      label: translation("type"),
      icon: "/icons/icon_house_remaxrd.svg",
      component: TypeFilters,
      onTypeChange: onTypeChange,
      typeProperty: filters.typeProperty,
      value: filters.typeProperty,
      collection: collection,
      id: "filterType"
    },
    {
      label: "Sector",
      icon: "/icons/location.svg",
      component: SectorFilters,
      onChange: onSectorsChange,
      value: filters.sector,
      id: "filterSector"
    },
    {
      label: translation("bedrooms"),
      icon: "/icons/icon_bed_remaxrd_white.svg",
      component: RoomFilters,
      onChange: onBedroomsChange,
      value: filters.bedrooms,
      id: "filterBedrooms"
    },
    {
      label: translation("prices"),
      icon: "/icons/currency.svg",
      component: PriceRangeFilters,
      onChange: onCurrencyChange,
      valueCurrencyType: filters.currencyType,
      onChangePrice: onAmountChange,
      valuePrice: [filters.amountMin, filters.amountMax],
      id: "filterPrice"
    },
    // {
    //   label: translation("baths"),
    //   icon: "/icons/icon_bathtub_remaxrd_white.svg",
    //   component: BathtubFilters,
    //   onChange: onBathroomsChange,
    //   value: filters.bathrooms,
    // },
    // {
    //   label: translation("parkings"),
    //   icon: "/icons/icon_car_remaxrd_white.svg",
    //   component: ParkingLotsFilters,
    //   onChange: onParkingLotsChange,
    //   value: filters.parkings,
    // },
  ];

  const advancedFiltersSubmit = (values: any) => {
    handleAdvancedFilters(values);
  };

  const handleCheckedState = (value: string) => {
    return filters.businessTypes === value;
  };

  const countAdvancedFilters = (filters: any) => {
    let desktopIgnoredFilters = [
      "city",
      "locations",
      "page",
      "businessTypes",
      "typeProperty",
      "parkings",
      "bedrooms",
      "bathrooms",
      "collection",
      "referer",
      "areferer",
    ];

    let mobileIgnoredFilters = [
      "city",
      "sector",
      "locations",
      "page",
      "collection",
      "referer",
      "areferer",
    ];

    const width: any = size?.width;
    let ignoredFilters =
      width > 991 ? desktopIgnoredFilters : mobileIgnoredFilters;

    let counter = 0;

    Object.keys(filters).forEach((key) => {
      if (!ignoredFilters.includes(key) && !isNull(filters[key])) {
        counter++;
      }
    });

    return counter;
  };

  const isNull = (value: Array<any> | number | string) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }

    if (value) {
      return false;
    }

    return true;
  };

  const countFilters = (value: any) => {
    if (Array.isArray(value)) {
      return value.length;
    } else if (value) {
      return value === ">4" ? "4+" : value;
    }

    return 0;
  };

  const onCustomLabelChange = () => {
    const value = filters.businessTypes == "sale" ? "lease" : "sale";

    onBusinessChange(value);
  };

  const filtersCount = countAdvancedFilters(filters);

  const handleReset = (values: any) => {
    advancedFiltersSubmit(FormConfig.initialValues);
  };

  const ConfigForm: FormikConfig<any> = {
    initialValues: filters,
    onSubmit: advancedFiltersSubmit,
    enableReinitialize: true,
  };

  return (
    <Section collection={collection} showNews={showNews}>
      {!isMobile && (
        <SearchWrapper
          width={size.width}
          collection={collection}
          showNews={showNews}
        >
          <Search>
            <SearchBar
              value={filters.locations}
              onChange={onLocationsChange}
              collection={collection}
            />
          </Search>
        </SearchWrapper>
      )}
      <Wrapper collection={collection}>
        <FlexWrapper>
          {!isMobile && (
            <StyledRadioTab
              radioGroup="businessTypes"
              onChange={onBusinessChange}
            >
              <StyledRadio
                id="sale"
                value="sale"
                checked={handleCheckedState("sale")}
              >
                <CustomLabel
                  label={translation("sale")}
                  active={filters.businessTypes == "sale"}
                  onChange={onCustomLabelChange}
                />
              </StyledRadio>
              <StyledRadio
                id="lease"
                value="lease"
                checked={handleCheckedState("lease")}
              >
                <CustomLabel
                  label={translation("lease")}
                  active={filters.businessTypes == "lease"}
                  onChange={onCustomLabelChange}
                />
              </StyledRadio>
            </StyledRadioTab>
          )}
          {!isMobile && (
            <OptionsWrapper>
              {filtersOptions.map((item, index) => {
                const { label, icon, component, id, ...rest } = item;
                const count = countFilters(rest.value);

                const Children = (
                  <FilterWrapper>
                    <FilterOptions bg="--color-blue-main" key={label+id} id={id}>
                      <OptionsImg src={icon} />
                      {label}
                    </FilterOptions>
                    {Boolean(count) && (
                      <Badge style={{ backgroundColor: "#e62f34" }}>
                        {count}
                      </Badge>
                    )}
                  </FilterWrapper>
                );

                return React.createElement(
                  component,
                  {
                    ...rest,
                    sectorsCheckedList:
                      label == "Sector" ? filters?.sector : [],
                    queryFilters: props.filters
                  },
                  Children
                );
              })}
            </OptionsWrapper>
          )}
          <DialogForm
            {...{ ConfigForm, filtersCount, translation, handleReset }}
          />
        </FlexWrapper>
      </Wrapper>
    </Section>
  );
}

const DialogForm = (props: any) => {
  const { ConfigForm, filtersCount, translation,handleReset } = props;
  const width = useWidth();
  const isMobile = ["xs", "sm"].includes(width);
  return (
    <Formik {...ConfigForm}>
      {(pf: FormikProps<any>) => (
        <Form>
          <AdvancedFilters
            handleSubmit={() => pf.handleSubmit()}
            handleReset={() => {handleReset()}}
          >
            <AdvancedFilter id="modal-propertyList">
              <FilterImg src="/icons/filter_remax_rd.svg" alt="Filter icon" />
              <FilterText>
                { filtersCount && isMobile ? "EDITAR FILTROS" : translation("filters") }
              </FilterText>
              {filtersCount > 0 && <Badge>{filtersCount}</Badge>}
            </AdvancedFilter>
          </AdvancedFilters>
        </Form>
      )}
    </Formik>
  );
};

const StyledRadio = styled(RadioTabs)`
  & > label {
    margin-top: 2px;
    padding-top: 14px;
    padding-bottom: 13px;
  }
`;

const Search = styled.div`
  width: 100%;

  @media screen and (max-width: 1420px) {
    width: 650px;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

const MobileFilters = styled.div`
  display: none;

  @media screen and (max-width: 991px) {
    display: block;
    position: relative;
  }
`;

const MobileWrapper = styled.div`
  background-color: var(--white);
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0px 20px;
`;

const SearchWrapper = styled.div<ISearchWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1101;
  position: fixed;

  ${(props) => {
    if (props.collection) {
      return "top: 2.5px;";
    } else {
      return "top: 6px;";
    }
  }}

  ${(props) => {
    if (props.showNews) {
      return css`
        top: 56px;
      `;
    }
  }}

  @media screen and (min-width: 1421px) {
    ${(props) => {
      if (props.width) {
        return css`
          width: ${0.37 * props.width}px;
          left: ${0.15 * props.width}px;
        `;
      }
    }}
  }

  @media screen and (max-width: 1420px) {
    top: 0;
    position: static;
    background-color: var(--white);
    padding-top: 10px;
    padding-bottom: 10px;

    ${(props) => {
      if (props.collection) {
        return css`
          padding-top: 40px;
          padding-bottom: 40px;
        `;
      }
    }}
  }

  @media screen and (max-width: 991px) {
    background-color: #f3f3f5;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Section = styled.div<ISection>`
  position: relative;

  @media screen and (max-width: 1420px) {
    position: sticky;
    top: 72px;
    z-index: 501;

    ${(props) => {
      if (props.collection && props.showNews) {
        return css`
          top: 109px;
          margin-top: -10px;
        `;
      }
      if (props.collection && !props.showNews) {
        return css`
          top: 109px;
          margin-top: -10px;
        `;
      }
      if(props.showNews){
        return css`
          top: 114px;
        `;
      }
    }}
  }

  @media screen and (max-width: 600px) {
    ${(props) => {
      if (props.collection) {
        return css`
          top: 72px;
        `;
      }
    }}
  }
`;

const Wrapper = styled.div<IWrapper>`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  overflow:hidden;
  background: #e6e6e6;
  padding-bottom: 9px;
  padding-top: 0;
  z-index: 501;
  transform: translateY(-3px);

  ${(props) => {
    if (props.collection) {
      return css`
        padding-left: 80px;
        margin-top: -6px;

        @media screen and (max-width: 1420px) {
          padding-left: 0;
        }
      `;
    }
  }}

  @media screen and (max-width: 1420px) {
    position: static;
    padding-top: 0;
  }
  @media screen and (max-width: 990px) {
    background-color: white;
    justify-content: flex-end;
    padding: 0px 10%;
  }
  @media screen and (max-width: 899px) {
    position: relative;
    top: -8px;
    padding-top: 10px;
  }
`;

const FilterOptions = styled(Button)`
  border-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-transform: capitalize;
  padding: 11px 13px;
`;

const FilterWrapper = styled.div`
  position: relative;
`;

const AdvancedFilter = styled(Button)`
  border-radius: 15px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 10px;
  background-color: #e62f34;

  @media screen and (max-width: 991px) {
    font-size: ${10 / 16}rem;
  }
`;

const FilterImg = styled.img`
  width: 11px;
  height: 11px;
  vertical-align: middle;
  margin-right:5px;
`;

const FilterText = styled.span``;

const OptionsImg = styled.img`
  margin-right: 4px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
`;

const StyledRadioTab = styled(RadioTab)`
  margin-right: 30px;
`;

const OptionsWrapper = styled.div`
  display: flex;
  margin-right: 33px;

  & ${FilterOptions} {
    margin-right: 7px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const Badge = styled.div`
  min-width: 20px;
  min-height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: var(--white);
  background-color: var(--color-blue-main);
  font-size: ${12 / 16}rem;
  font-weight: 600;
  top: 30px;
  right: 0;
`;

const Label = styled.span`
  cursor: pointer;
  font-weight: 500;
  font-family: var(--font-family);
  width: 100%;
  text-align: center;
  font-size: 12px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  margin-right: 5px;

  & .MuiSvgIcon-root {
    width: 15px;
    height: 15px;
    color: var(--white);
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-height: 40px;
`;

interface ISearchWrapper {
  width: number | undefined;
  collection: boolean;
  showNews: boolean;
}

interface IWrapper {
  collection: boolean;
}

interface ISection {
  collection: boolean;
  showNews: boolean; // this prop is used to show the news on navbar
}

export default Filters;
