import React, { useEffect, useMemo, useState } from "react";
import { useTranslations } from "next-intl";

import Menu from "./Menu";
import styled from "@emotion/styled";
import { CCurrencyTypes } from "shared/models/const/currencyTypes";
import LabelField from "shared/components/label-field";
import Grid from "@mui/material/Grid";
import { DefaultSwitchLabels } from "shared/components/switch-labels";
import { DefaultTextField } from "shared/components/text-field";
import { MoneyInput } from "shared/components/mask-components";
import { debounce } from "lodash";
import Button from '@mui/material/Button'

export default function PriceRangeFilters(props: any) {
  const { children, ...rest } = props;
  const Children = React.cloneElement(children);
  const Content = React.createElement(PriceComponent, {
    ...rest,
  });

  return <Menu content={Content}>{Children}</Menu>;
}

const PriceComponent = (props: any) => {
  const translations = useTranslations("formRealestateFilters");
  const { onChange, valueCurrencyType, valuePrice, onChangePrice } = props;
  const debouncedChangePrice = useMemo(
    () => debounce(onChangePrice, 500),
    [onChangePrice]
  );
  const [min, setMin] = useState(valuePrice[0]);
  const [max, setMax] = useState(valuePrice[1]);
  const [current, setCurrent] = useState(valueCurrencyType);
  const [error, setError] = useState("");

  useEffect(() => {
    if (+max && +max < +min) {
      setError(translations("price_range-error_message"));
      return;
    }
    setError("");
  }, [min, max]);

  const SubmitChanges = (e: any) => {
    e.preventDefault();
    onChangePrice([min, max, current]);
    // onChange();
  }
  return (
    <Wrapper>
      <Grid
        container
        sx={{ paddingY: 2 }}
        alignItems={"center"}
        spacing={1}
        justifyContent="center"
      >
        <Grid
          container
          item
          xs={12}
          spacing={1}
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Grid item xs>
            <LabelField label="Precio" />
          </Grid>
          <Grid item xs>
            <DefaultSwitchLabels
              onChange={(e) => {
                setCurrent(e);
                setMin("");
                setMax("");
              }}
              value={current}
              options={CCurrencyTypes}
            />
          </Grid>
          <Grid item container spacing={1} marginY={3}>
            {error ? (
              <Grid item xs={12}>
                <ErrorMessage>{error}</ErrorMessage>
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <DefaultTextField
                placeholder={translations("minimum")}
                inputComponent={MoneyInput}
                name="amountMin"
                value={min}
                onChange={(e) => {
                  setMin(e);
                }}
              ></DefaultTextField>
            </Grid>

            <Grid item xs={6}>
              <DefaultTextField
                inputComponent={MoneyInput}
                placeholder={translations("maximum")}
                name="amountMax"
                value={max}
                onChange={(e) => {
                  setMax(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    SubmitChanges(e);
                  }
                }}
                
              ></DefaultTextField>
            </Grid>
          </Grid>

          <Grid container xs={12} justifyContent={"center"}>
            <CustomButton variant="contained" onClick={SubmitChanges}>
              {translations("btnApplyPrice")}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #f7f6f4;
  border-radius: 10px;
  padding: 18px 13px 13px;
  max-width: 380px;
`;

const CustomButton = styled(Button)`
  border-radius: 20px;
  font-size: 13px;
  min-width: 120px;
  text-transform: capitalize;
  font-weight: 600;
`;
const ErrorMessage = styled.span`
  color: var(--color-red-error);
`;
