import { SectorItem } from "@interfaces/response/sector.interface";
import { useQuery } from "@tanstack/react-query";
import { getSector } from "api/sector.request";

interface UseSectorHookProps {
    idCity: number | undefined;
    onSuccess?: (data: SectorItem[]) => void;
}

const useSectors = ({ idCity, onSuccess }: UseSectorHookProps) => {
    const { data, isLoading } = useQuery(
        ["list-sectors", idCity],
        () => getSector({ cityId: idCity }),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            enabled: !!idCity,
            onSuccess: ({ data }) => onSuccess?.(data),
        }
    );

    return { data, isLoading };
};

export default useSectors;